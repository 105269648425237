/* Originally from the Slate Theme for GitHub Pages */
/* by Jason Costello, @jsncostello                  */

/* Theme styles */

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  color:#373737;
  background: #fff;
  font-size: 14px;
  font-family: 'Myriad Pro', Calibri, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  overflow: scroll;
  -webkit-font-smoothing: antialiased;
}
h1, h2, h3 {
  margin: 10px 0;
  font-weight: 700;
  color:#222222;
  font-family: 'Lucida Grande', 'Calibri', Helvetica, Arial, sans-serif;
  letter-spacing: -1px;
}
h1 {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
}
header h1 {
  text-align: left;
}
h2 {
  padding-bottom: 10px;
  font-size: 32px;
  background: url('/images/bg_hr.png') repeat-x bottom;
}
h3 {
  font-size: 24px;
}
a {
  text-decoration: none;
  color: #007edf;
  text-shadow: none;
}
a:hover,
a:focus {
  text-decoration: underline;
}
table {
  border: 1px solid #373737;
  margin-bottom: 20px;
  text-align: left;
}
th {
  font-family: 'Lucida Grande', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 5px;
  background: #373737;
  color: #fff;
}

td {
  padding: 5px;
  border: 1px solid #373737;
}

/* Full-width styles */

.outer {
  width: 100%;
}
.inner {
  position: relative;
  max-width: 1000px;
  padding: 20px 10px;
  margin: 0 auto;
}
#main_content_wrap {
  background: #fff;
  border-top: 1px solid #111;
}
#main_content {
  padding-top: 20px;
}

/* Small Device Styles */

@media screen and (max-width: 670px) {
  body {
    font-size:14px;
  }
  .inner {
    min-width: 480px;
    max-width: 670px;
  }
  header h1 {
    font-size: 32px;
  }
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 21px;
  }
}
