#controls {
  float: left;
  width: 200px;
  padding-right: 20px;
  border-right: 1px dashed gray;
}
#controls div {
  margin: 20px 0px;
}
#controls h4 {
  margin: 0px 0px 5px 0px;
}
#controls input[type="text"] {
  width: 100%;
}
#controls ul {
  list-style: none;
  margin: 0px;
  padding-left: 0px;
}
#controls ul li input {
  margin-right: 3px;
}
