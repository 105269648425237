#git-stats h3 {
  margin: 25px 0px 10px 0px;
}
#git-stats h3 a {
  color: #222222;
}
#git-stats #overview ul {
  margin-top: 0px;
}
#git-stats #overview h4 {
  font-weight: bold;
  color: #222222;
  margin: 15px 0px 0px 0px;
}
#git-stats #metadata {
  text-align: center;
  margin-top: -15px;
  margin-bottom: 15px;
}
#git-stats .count {
  vertical-align: super;
  font-size: smaller;
  margin-left: 4px;
}
