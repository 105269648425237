#header_wrap {
  background: #212121;
}
#header_wrap .inner {
  padding: 15px 10px 5px 10px;
}
#header_wrap .inner h1 {
  margin: 0;
  color: #fff;
  font-size: 42px;
  font-weight: 700;
  text-shadow: #111 0px 0px 10px;
}
#menu a {
  color: #0090ff;
  font-size: 18px;
  font-weight: 300;
  background: none;
  clear: none;
  margin: 0px 30px 0px 0px;
  outline: 0;
}
#menu a:hover {
  color: #fff;
}
#menu a.active span.bullet {
  color: #fff;
}
